import { render, staticRenderFns } from "./UpdateMerchantSettingsModal.vue?vue&type=template&id=bc7d674c&scoped=true"
import script from "./UpdateMerchantSettingsModal.vue?vue&type=script&lang=js"
export * from "./UpdateMerchantSettingsModal.vue?vue&type=script&lang=js"
import style0 from "./UpdateMerchantSettingsModal.vue?vue&type=style&index=0&id=bc7d674c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc7d674c",
  null
  
)

export default component.exports