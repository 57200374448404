<template>
  <b-modal
    id="modal-merchant-settings-update"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="UPDATE MERCHANT SETTINGS"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="updateMerchantBusinessSettingsForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickUpdateMerchantBusinessSettingsDetails)"
        >
          <b-tabs>
            <b-tab
              lazy
              title="Invoice Setting"
            >
              <div v-if="activeTab === 'activeTab'">
                <b-card title="Merchant Settings">
                  <b-row>
                    <b-col sm="12">
                      <b-form-group
                        label="Invoice Date"
                        label-for="invoice-date"
                        class="dateFormGroup"
                      />
                      <b-col class="dateCol d-flex flex-wrap">
                        <b-col
                          sm="12"
                          md="6"
                          class="pr-md-0"
                        >
                          <b-row class="d-flex flex-wrap">
                            <div class="d-flex mt-1 mb-1 flex-wrap align-items-center">
                              <div class="d-flex align-items-center flex-grow-1">
                                <div class="every">
                                  Every
                                </div>
                                <div class="input-select-row">
                                  <b-form-input
                                    id="period"
                                    v-model="period"
                                    autocomplete="nope"
                                    class="mr-1 inputField"
                                  />
                                </div>
                                <v-select
                                  id="selectedType"
                                  v-model="selectedType"
                                  :options="dowOptions"
                                  :reduce="option => option.name"
                                  label="name"
                                  class="mr-1 dropdown"
                                  placeholder="Select option"
                                >
                                  <template v-slot:option="option">
                                    {{ option.name }}
                                  </template>
                                </v-select>
                              </div>
                            </div>
                          </b-row>
                          <b-row v-if="selectedType === 'Week'">
                            <div
                              v-for="day in days"
                              :key="day"
                              class="day-column mb-1"
                              :class="{ 'selected': day === selectedDay }"
                              @click="selectDay(day)"
                            >
                              {{ day }}
                            </div>
                          </b-row>
                        </b-col>
                        <b-col
                          sm="12"
                          md="3"
                          class="mb-1 ml-md-5 ml-0 mt-1 calendar-alignment"
                        >
                          <b-calendar
                            v-model="value"
                            class="calendar"
                            hide-header
                            today-variant="primary"
                            selected-variant="primary"
                            locale="en"
                            :date-info-fn="dateClass"
                            :date-disabled-fn="dateDisabled"
                            @context="onContext"
                          />
                        </b-col>
                      </b-col>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                      class="mt-1"
                    >
                      <b-form-group label-for="invoice-lead-period">
                        <template>
                          <div class="label">
                            Invoice Lead Period
                            <feather-icon
                              v-b-tooltip.hover.right.v-primary="'Invoice Lead Period lets you adjust the invoice creation timing after orders are updated to Delivered. It accommodates delays in cash deposit timelines, ensuring smoother customer transactions and financial processes for your Branch.'"
                              icon="AlertCircleIcon"
                              size="12"
                              class="cursor-pointer text-primary ml-1"
                            />
                          </div>
                        </template>
                        <b-form-input
                          id="invoice-lead-period"
                          v-model="invoice_lead_period"
                          autocomplete="nope"
                          class="icon"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card
                  title="Commision Settings"
                >
                  <b-row>
                    <b-col
                      sm="12"
                      md="6"
                    >
                      <b-form-group
                        label="Commission"
                        label-for="v-email"
                      >
                        <b-form-input
                          id="v-email"
                          v-model="commission"
                          autocomplete="nope"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </div>
            </b-tab>
            <b-tab
              lazy
              title="Waybill Setting"
            >
              <div v-show="activeTab === 'activeTab'">
                <b-card
                  title="WAYBILL GENERATION"
                >
                  <b-row>
                    <b-card-text class="ml-1">
                      Select this option if you need to generate waybills automatically by the system.
                      Keep unselected if you need to add the waybill numbers manually
                      <span style="color: red">*</span>
                    </b-card-text>

                    <!-- Radio Selection -->
                    <b-col cols="6">
                      <b-form-radio-group
                        v-model="waybillGenSelected"
                        :options="waybillGenOptions"
                        class="demo-inline-spacing mb-1"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                      />
                    </b-col>
                  </b-row>
                </b-card>
                <b-card>
                  <b-overlay
                    id="overlay-background"
                    :show="loading"
                    variant="light"
                    opacity="0.30"
                    blur="10px"
                    rounded="sm"
                  >
                    <b-table
                      striped
                      hover
                      responsive
                      :per-page="perPage"
                      :items="rows"
                      :fields="fields"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      show-empty
                    >
                      <template #head(id)>
                        <div class="d-flex align-items-start">
                          <div>
                            <b-form-checkbox
                              id="selectAllCheckbox"
                              v-model="selectAllCheckbox"
                              name="selectAllCheckbox"
                              @change="onClickSelectAllCheckbox()"
                            />
                          </div>
                        </div>
                      </template>
                      <template #cell(id)="data">
                        <div class="d-flex align-items-start">
                          <div>
                            <div class="font-weight-bolder">
                              <b-form-checkbox
                                v-model="selectedWaybillArray"
                                :value="data.item.id"
                                :checked="isSelected(data.item.id)"
                                @change="handleCheckboxChange(data.item.id)"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                    </b-table>
                    <div class="mb-1">
                      <b-row
                        cols="8"
                      >
                        <b-col
                          class="d-flex align-items-center justify-content-sm-start"
                          md="3"
                        >
                          <label class="width-75">Per page</label>
                          <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="pageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block width-125"
                          />
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                          class="d-flex align-items-center justify-content-sm-end"
                        >
                          <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                          <b-pagination
                            v-model="page"
                            :per-page="perPage"
                            :total-rows="(meta.total)? meta.total : 0"
                            class="mb-0 mt-1 mt-sm-0"
                            first-number
                            last-number
                            next-class="next-item"
                            prev-class="prev-item"
                          >
                            <template #prev-text>
                              <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                              />
                            </template>
                            <template #next-text>
                              <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                              />
                            </template>
                          </b-pagination>
                        </b-col>
                      </b-row>
                    </div>
                  </b-overlay>
                </b-card>
              </div>
            </b-tab>
            <b-tab
              v-if="merchantType"
              lazy
              title="Merchant Type Setting"
            >
              <div v-show="activeTab === 'activeTab'">
                <b-card
                  v-if="merchantType"
                  title="MERCHANT TYPE"
                >
                  <b-row>
                    <!-- Radio Selection -->
                    <b-col cols="12">
                      <b-form-radio-group
                        v-model="merchantTypeSelected"
                        :options="merchantTypeOptions"
                        class="demo-inline-spacing mb-1"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </div>
            </b-tab>
            <b-tab
              v-if="pickupValue"
              lazy
              title="Pickup Enable Setting"
            >
              <div v-show="activeTab === 'activeTab'">
                <b-card
                  v-if="pickupValue"
                  title="PickUp Enable"
                >
                  <b-row>
                    <b-card-text class="ml-1">
                      Select this option if you need to enable pickup
                      Keep unselected if you need to Disable the pickup
                      <span style="color: red">*</span>
                    </b-card-text>

                    <!-- Radio Selection -->
                    <b-col cols="6">
                      <b-form-radio-group
                        v-model="pickUpEnaSelected"
                        :options="pickUpEnaOptions"
                        class="demo-inline-spacing mb-1"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </div>
            </b-tab>
          </b-tabs>
          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              class="mr-1 mb-1 custom-button-color"
            >
              Submit
            </b-button>
            <!--      <b-button-->
            <!--        v-ripple.400="'rgba(186, 191, 199, 0.15)'"-->
            <!--        type="reset"-->
            <!--        variant="outline-secondary"-->
            <!--      >-->
            <!--        Reset-->
            <!--      </b-button>-->
          </b-col>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BFormCheckbox,
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BCard,
  BCardText,
  BFormRadioGroup,
  BRow,
  BOverlay,
  VBModal,
  BCalendar,
  VBTooltip,
  BTable,
  BPagination,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

const MerchantRepository = RepositoryFactory.get('merchant')
const MerchantSettingsRepository = RepositoryFactory.get('merchantSetting')
const waybillSettingsRepository = RepositoryFactory.get('waybillSetting')

function initialState() {
  const currentDate = new Date()

  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1 and pad with '0' if needed.
  const day = String(currentDate.getDate()).padStart(2, '0') // Pad with '0' if needed.

  const formattedDate = `${year}-${month}-${day}`

  return {
    selectAllCheckbox: false,
    autoPageWithSelectAll: [],
    manualPageWithSelectAll: [],
    activeTab: 'activeTab',
    open: false,
    id: null,
    form: {},
    rows: [],
    modalLoading: false,
    loading: false,
    merchantRoleOptions: [],
    invoice_period: 0,
    invoice_lead_period: 0,
    commission: 0,
    merchantTypeSelected: 'cash',
    merchantTypeOptions: [
      { text: 'Cash', value: 'cash', disabled: false },
      { text: 'Credit', value: 'credit', disabled: false },
    ],
    waybillGenSelected: true,
    waybillGenOptions: [
      { text: 'Auto', value: true, disabled: false },
      { text: 'Manual', value: false, disabled: false },
    ],

    pickUpEnaSelected: '0',
    pickUpEnaOptions: [
      { text: 'Enabled', value: true, disabled: false },
      { text: 'Disabled', value: false, disabled: false },
    ],
    pickupValue: false,
    merchantType: false,
    dowOptions: [
      { name: 'Day' },
      { name: 'Week' },
    ],
    selectedType: '',
    days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    selectedDay: '',
    period: 0,
    value: '',
    count: 0,
    viewingMonth: currentDate.getMonth(),
    lastWeekCount: 0,
    appliedDate: formattedDate,
    dayInNumber: null,
    todayYMD: formattedDate,
    meta: {},
    sort: '',
    perPage: 10,
    pageOptions: [10, 20],
    totalRows: 1,
    page: 1,
    selectedKey: '',
    selectedWaybillArray: [],
    currentSelectedSeries: [],
    selectedAutoSeries: [],
    selectedManualSeries: [],
    fields: [
      {
        key: 'id',
        thClass: 'text-left',
        tdClass: 'text-left',
      },
      {
        key: 'series_name',
        label: 'Series Name',
        thClass: 'text-left',
        tdClass: 'text-left',
        sortable: true,
      },
      {
        key: 'prefix',
        label: 'Prefix',
        thClass: 'text-left',
        tdClass: 'text-left',
        sortable: true,
      },
      {
        key: 'suffix',
        label: 'Suffix',
        thClass: 'text-left',
        tdClass: 'text-left',
        sortable: true,
      },
      {
        key: 'preview',
        label: 'Preview',
        thClass: 'text-left',
        tdClass: 'text-left',
        sortable: true,
      },
    ],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormCheckbox,
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BCard,
    BCardText,
    BFormRadioGroup,
    BRow,
    BOverlay,
    BCalendar,
    BTabs,
    BTab,
    BTable,
    BPagination,
    FeatherIcon,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  watch: {
    page() {
      this.fetchActiveWaybillSeries()
    },
    perPage() {
      this.fetchActiveWaybillSeries()
    },
    waybillGenSelected() {
      this.fetchActiveWaybillSeries()
    },
  },
  methods: {
    async openModal(merchantId) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.merchantId = merchantId
      this.modalLoading = true
      await this.fetchMerchantUserDetails()
      await this.fetchAssignedWaybillSeries()
      await this.fetchActiveWaybillSeries()
      await this.getPickupSetting()
      this.modalLoading = false
    },
    async fetchMerchantUserDetails() {
      try {
        const { data } = (await MerchantRepository.getMerchantBusinessSettingsDetails(this.merchantId)).data
        this.period = data.invoice_period
        this.invoice_lead_period = data.invoice_lead_period
        this.commission = data.commission
        this.merchantTypeSelected = data.merchant_type
        this.waybillGenSelected = data.is_waybill_auto
        this.pickUpEnaSelected = data.is_pickup_enable
        this.selectedType = data.invoicing_cycle
        this.selectedDay = data.day_of_week
        const [firstWord] = data.next_invoice_generate_date.split(' ')
        this.appliedDate = firstWord
        if (this.selectedDay === 'Mon') {
          this.dayInNumber = 1
        } else if (this.selectedDay === 'Tue') {
          this.dayInNumber = 2
        } else if (this.selectedDay === 'Wed') {
          this.dayInNumber = 3
        } else if (this.selectedDay === 'Thu') {
          this.dayInNumber = 4
        } else if (this.selectedDay === 'Fri') {
          this.dayInNumber = 5
        } else if (this.selectedDay === 'Sat') {
          this.dayInNumber = 6
        } else if (this.selectedDay === 'Sun') {
          this.dayInNumber = 0
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async fetchActiveWaybillSeries() {
      this.modalLoading = true
      const isChecked = this.waybillGenSelected ? this.autoPageWithSelectAll.includes(this.page) : this.manualPageWithSelectAll.includes(this.page)
      if (!isChecked) {
        this.selectAllCheckbox = false
      } else {
        this.selectAllCheckbox = true
      }
      try {
        const { data } = (await waybillSettingsRepository.getActiveWaybillSeriesList(this.waybillGenSelected, this.page, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    async fetchAssignedWaybillSeries() {
      this.modalLoading = true
      try {
        const { data } = (await waybillSettingsRepository.fetchAssignedWaybillSeries(this.merchantId))
        const assignedWaybillSeries = data.data
        this.selectedWaybillArray = assignedWaybillSeries.map(n => n.waybill_series_id)
        this.selectedAutoSeries = assignedWaybillSeries.filter(n => n.waybill_series.is_auto).map(n => n.waybill_series_id)
        this.selectedManualSeries = assignedWaybillSeries.filter(n => n.waybill_series.is_auto === false).map(n => n.waybill_series_id)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    isSelected(id) {
      if (this.waybillGenSelected) {
        // Check if the id is in the selectedAutoSeries array
        return this.selectedAutoSeries.includes(id)
      }
      return this.selectedManualSeries.includes(id)
    },
    handleCheckboxChange(id) {
      // Check if the id is already selected
      const index = this.waybillGenSelected ? this.selectedAutoSeries.indexOf(id) : this.selectedManualSeries.indexOf(id)
      if (index > -1) {
        if (this.waybillGenSelected) {
          // If the id is already in the array, remove it
          this.selectedAutoSeries.splice(index, 1)
        } else {
          // If the id is already in the array, remove it
          this.selectedManualSeries.splice(index, 1)
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.waybillGenSelected) {
          // If the id is not in the array, add it
          this.selectedAutoSeries.push(id)
        } else {
          // If the id is not in the array, add it
          this.selectedManualSeries.push(id)
        }
      }
    },
    async onClickUpdateMerchantBusinessSettingsDetails() {
      this.modalLoading = true
      try {
        const payload = {
          business_setting: {
            invoice_period: this.period,
            invoice_lead_period: this.invoice_lead_period,
            commission: this.commission,
            vat: 0,
            is_waybill_auto: this.waybillGenSelected,
            is_pickup_enable: this.pickUpEnaSelected,
            invoicing_cycle: this.selectedType,
            next_invoice_generate_date: this.appliedDate,
            merchant_type: this.merchantTypeSelected,
          },
        }

        if (this.selectedType === 'Week') {
          payload.business_setting.day_of_week = this.selectedDay
        }

        if (this.waybillGenSelected && this.selectedAutoSeries.length === 0) {
          this.showErrorMessage('Please select at least one auto waybill series')
        } else if (!this.waybillGenSelected && this.selectedManualSeries.length === 0) {
          this.showErrorMessage('Please select at least one manual waybill series')
        } else {
          await MerchantRepository.updateMerchantBusinessSettings(this.merchantId, payload)
          this.assignWaybillSeries()
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    async assignWaybillSeries() {
      try {
        const payload = {
          merchant_id: this.merchantId,
          waybill_series_id: this.selectedWaybillArray,
        }
        await waybillSettingsRepository.assignWaybillSeries(payload)
        this.showSuccessMessage('Successfully Updated')
        localStorage.removeItem('merchant_list')
        this.open = false
        setTimeout(() => {
          this.$router.go()
        }, 2200)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getPickupSetting() {
      try {
        const { data } = (await MerchantSettingsRepository.getMerchantSettingsResource()).data
        this.pickupValue = data.merchant_pickup_enable
        this.merchantType = data.merchant_type_select
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    onContext(ctx) {
      if (ctx.selectedYMD === '' && this.appliedDate === null) {
        this.appliedDate = this.todayYMD
      }

      if (ctx.selectedYMD !== '' && this.appliedDate !== null) {
        this.appliedDate = ctx.selectedYMD
      }
    },
    onClickSelectAllCheckbox() {
      if (this.selectAllCheckbox) {
        if (this.waybillGenSelected) {
          const newAutoIds = this.rows.map(n => n.id).filter(id => !this.selectedAutoSeries.includes(id))
          this.selectedAutoSeries = [...this.selectedAutoSeries, ...newAutoIds]
          this.selectedWaybillArray = [...this.selectedWaybillArray, ...newAutoIds]
          this.autoPageWithSelectAll = [...this.autoPageWithSelectAll, this.page]
        } else {
          const newManualIds = this.rows.map(n => n.id).filter(id => !this.selectedManualSeries.includes(id))
          this.selectedManualSeries = [...this.selectedManualSeries, ...newManualIds]
          this.selectedWaybillArray = [...this.selectedWaybillArray, ...newManualIds]
          this.manualPageWithSelectAll = [...this.manualPageWithSelectAll, this.page]
        }
      } else {
        this.selectedWaybillArray = this.selectedWaybillArray.filter(id => !this.rows.map(n => n.id).includes(id))

        if (this.waybillGenSelected) {
          this.selectedAutoSeries = this.selectedAutoSeries.filter(id => !this.rows.map(n => n.id).includes(id))
          this.autoPageWithSelectAll = this.autoPageWithSelectAll.filter(page => page !== this.page)
        } else {
          this.selectedManualSeries = this.selectedManualSeries.filter(id => !this.rows.map(n => n.id).includes(id))
          this.manualPageWithSelectAll = this.manualPageWithSelectAll.filter(page => page !== this.page)
        }
      }
    },
    selectDay(day) {
      if (day === this.selectedDay) {
        // Deselect the day if it's already selected
        this.selectedDay = ''
      } else {
        // Select the new day
        this.selectedDay = day
      }

      if (day === 'Mon') {
        this.dayInNumber = 1
      } else if (day === 'Tue') {
        this.dayInNumber = 2
      } else if (day === 'Wed') {
        this.dayInNumber = 3
      } else if (day === 'Thu') {
        this.dayInNumber = 4
      } else if (day === 'Fri') {
        this.dayInNumber = 5
      } else if (day === 'Sat') {
        this.dayInNumber = 6
      } else if (day === 'Sun') {
        this.dayInNumber = 0
      }
    },
    dateDisabled(ymd) {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1 and pad with '0' if needed.
      const currentDay = String(currentDate.getDate()).padStart(2, '0') // Pad with '0' if needed.
      const today = `${currentYear}-${currentMonth}-${currentDay}`

      return ymd <= today
    },
    dateClass(ymd, date) {
      const day = date.getDay()
      const month = date.getMonth()

      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1 and pad with '0' if needed.
      const currentDay = String(currentDate.getDate()).padStart(2, '0') // Pad with '0' if needed.
      const today = `${currentYear}-${currentMonth}-${currentDay}`

      if (this.period !== 0 && this.selectedType === 'Day') {
        if (this.viewingMonth === 0 && month === 11) {
          this.viewingMonth = 12
        }

        if (this.viewingMonth === 11 && month === 0) {
          this.viewingMonth = -1
        }

        if (this.viewingMonth === month && day === 0) {
          this.lastWeekCount = this.count
        }

        if (day === 0 && this.viewingMonth !== month && month < this.viewingMonth) {
          this.count = this.lastWeekCount
          this.lastWeekCount = 0
        }

        if (month > this.viewingMonth || month < this.viewingMonth) {
          this.viewingMonth = month
        }

        if (ymd === this.appliedDate) {
          this.count = this.period
        }

        if (ymd < this.appliedDate) {
          return ''
        }

        if (this.count < this.period) {
          this.count += 1
        }

        if (this.count / this.period === 1) {
          this.count = 0
          if (ymd >= today) {
            return 'table-info'
          }
        }
      }

      if (this.period !== 0 && this.dayInNumber !== null && this.selectedType === 'Week') {
        if (this.viewingMonth === 0 && month === 11) {
          this.viewingMonth = 12
        }

        if (this.viewingMonth === 11 && month === 0) {
          this.viewingMonth = -1
        }

        if (this.viewingMonth === month && day === 0) {
          this.lastWeekCount = this.weekCount
        }

        if (day === 0 && this.viewingMonth !== month && month < this.viewingMonth) {
          this.weekCount = this.lastWeekCount
          this.lastWeekCount = 0
        }

        if (month > this.viewingMonth || month < this.viewingMonth) {
          this.viewingMonth = month
        }

        if (ymd === this.appliedDate) {
          this.weekCount = this.period - 1
        }

        if (ymd < this.appliedDate) {
          return ''
        }

        if (this.weekCount < this.period && this.dayInNumber === day) {
          this.weekCount += 1
        }

        if (this.weekCount / this.period === 1 && this.dayInNumber === day) {
          this.weekCount = 0
          return 'table-info'
        }
      }

      return ''
    },
  },
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
.dateFormGroup{
  height: fit-content;
  margin-bottom: 0;
}
.dateCol{
  border: 1px solid #bbbaba;
  border-radius: 5px;
}
.every{
  margin-right: 8px;
}
.inputField{
  width: 50px
}
.dropdown{
  width: 160px;
}
.day-column {
  flex: 1;
  text-align: center;
  font-weight:400;
  cursor: pointer;
  border: 2px solid rgba(107, 103, 151, 0.262);
}
.selected {
  background-color: #007bff; /* Change this to your desired selected day color */
  color: white; /* Change this to your desired text color for selected day */
}
.highlighted-date {
  background-color: #007bff;
  color: white;
}
.label{
  font-size:12px;
  margin-bottom:4px;
}
@media (max-width: 768px) {
  .calendar-alignment {
    padding-left: 0;
  }
  .dateCol {
    overflow-x: auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .calendar-alignment {
    padding-left: 15px;
  }
  .dateCol {
    flex-direction: column;
  }
  .calendar {
    align-self: center;
    width: 100%;
    margin-left: 0;
  }
  .day-column {
    display: inline-block;
    margin-right: 5px;
    padding: 5px;
  }

}
</style>
