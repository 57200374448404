<template>
  <b-modal
    id="modal-merchant-update"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="UPDATE MERCHANT"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="updateMerchantForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickUpdateMerchant)"
        >
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Business Name"
                label-for="h-name"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the merchant business name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Ref No."
                label-for="h-refNo"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="refNo"
                  rules="required"
                >
                  <b-form-input
                    id="refNo"
                    v-model="form.refNo"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the ref no."
                    type="text"
                    class="col-md-6"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                v-can="'manage-merchant-status'"
                label="Status"
                label-for="h-status"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="status"
                  rules=""
                >
                  <b-form-group>
                    <b-form-checkbox
                      id="status"
                      v-model="form.status"
                      value="active"
                      unchecked-value="inactive"
                    >
                      Active
                    </b-form-checkbox>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- submit and reset -->
          <b-row>
            <b-col
              offset-md="4"
            >
              <div>
                <b-button
                  v-can="'edit-merchant'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="mr-1 custom-button-color"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  Clear
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BFormCheckbox,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const MerchantRepository = RepositoryFactory.get('merchant')

function initialState() {
  return {
    open: false,
    id: null,
    form: {},
    modalLoading: false,
    loading: false,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BFormCheckbox,
    BRow,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(merchantId) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.merchantId = merchantId
      this.modalLoading = true
      await this.fetchMerchantDetails()
      this.modalLoading = false
    },
    async fetchMerchantDetails() {
      try {
        const { data } = (await MerchantRepository.getMerchantDetails(this.merchantId)).data
        this.form = {
          name: data.name,
          refNo: data.ref_no,
          status: data.is_active,
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },

    async onClickUpdateMerchant() {
      this.modalLoading = true
      try {
        const payload = {
          name: this.form.name,
          is_active: this.form.status,
        }
        await MerchantRepository.updateMerchant(this.merchantId, payload)
        this.showSuccessMessage('Merchant updated successfully')
        this.open = false
        this.$parent.onClickRefresh()
        localStorage.removeItem('merchant_list')
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
