<template>
  <b-modal
    id="modal-merchant-update"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="UPDATE SALES INFORMATION"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="updateSalesPerson"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickSalesPerson)"
        >
          <b-row class="d-flex justify-content-center">
            <b-col cols="7">
              <b-form-group
                label="Sales Person"
                label-for="h-salesperson"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    id="sales_person"
                    v-model="form.sales_person"
                    placeholder="Enter The Sales Person"
                    type="text"
                    autocomplete="nope"
                    :state="form.sales_person.length < subjectBodyMaxChar"
                    class="char-textarea"
                    :class="{ 'text-danger': form.sales_person.length >= subjectBodyMaxChar }"
                    :maxlength="subjectBodyMaxChar"
                  />
                  <small
                    class="textarea-counter-value float-right"
                    :class="form.sales_person.length >= subjectBodyMaxChar ? 'bg-danger' : ''"
                  >
                    <span class="char-count">{{ form.sales_person.length }}</span> / {{ subjectBodyMaxChar }}
                  </small>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- submit and reset -->
          <b-row>
            <b-col
              offset-md="4"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="mr-1 mt-1 custom-button-color"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="mt-1"
                  @click="clearForm"
                >
                  Clear
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const MerchantRepository = RepositoryFactory.get('merchant')

function initialState() {
  return {
    open: false,
    id: null,
    form: {
      sales_person: '',
    },
    modalLoading: false,
    loading: false,
    subjectBodyMaxChar: 20,
    salesPersonId: null,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(merchantId) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.merchantId = merchantId
      this.modalLoading = true
      await this.fetchSalesPersonDetails()
      this.modalLoading = false
    },
    async fetchSalesPersonDetails() {
      try {
        const { data } = (await MerchantRepository.getSalesPersonDetails(this.merchantId)).data
        this.form = {
          sales_person: data.sales_person.name,
        }
        this.salesPersonId = data.sales_person ? data.sales_person.id : null
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async onClickSalesPerson() {
      this.loading = true
      try {
        const payload = {
          name: this.form.sales_person,
        }
        await MerchantRepository.updateSalesPerson(this.merchantId, this.salesPersonId, payload)
        this.showSuccessMessage('Sales Person updated successfully')
        this.open = false
        this.$parent.onClickRefresh()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },

    clearForm() {
      this.form.sales_person = ''
    },
  },
}
</script>
  <style scoped>
  @import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
  @import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
  @import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
  @import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
  .custom-button-color {
    background-color: #4186f5 !important;
    border-color: #4186f5 !important;
  }
  </style>
