<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Merchant Name"
            label-for="v-name"
          >
            <b-form-input
              id="v-name"
              v-model="filters['name']"
              placeholder="Search"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Merchant Ref Number"
            label-for="ref-no"
          >
            <b-form-input
              id="ref-no"
              v-model="filters['ref_no']"
              placeholder="Search"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Admin Name"
            label-for="v-ref-no"
          >
            <b-form-input
              id="v-ref-no"
              v-model="filters['admin_name']"
              placeholder="Search"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Created At"
            label-for="created_at"
          >
            <div class="position-relative">
              <flat-pickr
                id="created_at"
                v-model="filters['created_at']"
                :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                class="form-control"
              />
              <div
                v-if="filters['created_at']"
                class="flatpickr-cancel"
                @click="clearDate('created_at')"
              >
                <feather-icon icon="XIcon" />
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Active Status"
            label-for="active_status"
          >
            <v-select
              v-model="filters['is_active']"
              :options="statusOptions"
              :reduce="option => option.key"
              label="name"
              placeholder="Select Status"
            >
              <template
                v-slot:option="option"
                class="text-truncate"
              >
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Admin Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="filters['merchant_email']"
              placeholder="Search"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Admin Contact Number"
            label-for="contact_no"
          >
            <b-form-input
              id="contact_no"
              v-model="filters['admin_phone']"
              placeholder="Search"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Primary Business Contact Number"
            label-for="business_phone"
          >
            <b-form-input
              id="business_phone"
              v-model="filters['default_business_phone']"
              placeholder="Search"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            v-if="canShowSalesPerson"
            label="Sales Person Name"
            label-for="v-name"
          >
            <b-form-input
              id="v-name"
              v-model="filters['sales_person_name']"
              placeholder="Search"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Apply and Clear Filters -->
      <b-row class="mt-1">
        <b-col>
          <filter-buttons
            :filter-data="filters"
            :apply-filters="applyFilters"
            :clear-filters="clearFilters"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card
      no-body
    >
      <div>
        <div
          class="m-2"
        >
          <!-- input -->
          <b-row>
            <b-col
              cols="8"
              class="d-flex align-items-center justify-content-start mt-1 mb-md-0"
            >
              <b-button
                v-can="'create-merchant'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="custom-button-color"
                @click="onClickOpenAddMerchantModal"
              >
                <feather-icon icon="PlusIcon" />
                Add New
              </b-button>
            </b-col>
            <b-col
              class="d-flex align-items-center justify-content-end mt-1 mb-1 mb-md-0"
            >
              <ReportTableButtons
                :items="rows"
                :json_fieldz="json_fields"
                :name="`Merchants - Page ${page}.xls`"
                :bulkname="`Merchants.xls`"
                :fetch="fetchMerchantListNoPagination"
                @refresh="onClickRefresh"
              />
            </b-col>
          </b-row>
        </div>

        <!-- table -->
        <b-overlay
          id="overlay-background"
          variant="light"
          opacity="0.30"
          blur="10px"
          rounded="sm"
        >
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="rows"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            show-empty
            sticky-header="100vh"
            class="hide-vertical-scroll"
          >
            <template #empty>
              <TableDataFetching
                :rows="rows"
                :data-loading="dataLoading"
              />
            </template>
            <template #cell(name)="data">
              <div class="d-flex align-items-center">
                <div>
                  <b-avatar
                    size="32"
                    :text="avatarText(data.item.name)"
                    variant="light-primary"
                  />
                </div>
                <div>
                  <div class="font-weight-bolder ml-1">
                    {{ `${data.item.name}${data.item.setting.merchant_type === 'cash' ? "&nbsp;[&nbsp;C&nbsp;]" : ''}` }}
                  </div>
                  <div class="font-weight-bold ml-1">
                    {{ data.item.ref_no }}
                  </div>
                  <div class="font-small-2 ml-1">
                    {{ timestampToDateTime(data.item.created_at) }}
                  </div>
                  <div class="ml-1">
                    <div v-if="data.item.is_active === 'active'">
                      <b-badge variant="light-success">
                        Active
                      </b-badge>
                    </div>
                    <div v-if="data.item.is_active === 'inactive'">
                      <b-badge variant="light-danger">
                        Inactive
                      </b-badge>
                    </div>
                    <div v-if="data.item.is_active === 'new'">
                      <b-badge variant="light-primary">
                        New
                      </b-badge>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(default_address)="data">
              <div v-if="data.value !== ''">
                <div
                  class="d-flex align-items-center"
                >
                  <div>
                    <div class="">
                      {{ data.value.address_line_1 }} {{ data.value.address_line_2 }}
                      {{ data.value.address_line_3 }}
                    </div>
                    <div class="font-weight-bolder">
                      {{ data.value.city.name }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="ml-4">
                  <span>---</span>
                </div>
              </div>
            </template>
            <template #cell(contact_no)="data">
              <div class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder">
                    {{ data.item.default_address ? data.item.default_address.default_contact_details.length > 0 ? data.item.default_address.default_contact_details[0].phone || 'N/A' : 'N/A' : 'N/A' }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(admin_details)="data">
              <div class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder">
                    {{ data.item.admin ? `${data.item.admin.first_name} ${data.item.admin.last_name}` : '' }}
                  </div>
                  <div class="">
                    {{ data.item.admin ? data.item.admin.email : '' }}
                  </div>
                  <div class="">
                    {{ data.item.admin ? data.item.admin.phone : '' }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(rate_card)="data">
              <div class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder">
                    <router-link
                      v-if="data.item.rate_card_id != null"
                      :to="{ name: 'view-rate-card', params: { rateCardType: 'merchant', id: data.item.rate_card_id } }"
                    >
                      {{ data.item.rate_card }}
                    </router-link>
                    <router-link
                      v-else
                      class="text-muted"
                      to=""
                    >
                      N/A
                    </router-link>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(created_at)="data">
              <div class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder ml-1">
                    {{ timestampToDateTime(data.item.created_at) }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(sales_person.name)="data">
              <div class="d-flex align-items-center">
                <div>
                  <div
                    :class="{
                      'font-weight-bolder': true,
                      'text-muted': !data.item.sales_person
                    }"
                  >
                    {{ data.item.sales_person ? data.item.sales_person.name : 'N/A' }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                :class="rows.length !== 1 ? 'dropdown-no-scroll':''"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="12"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-can="'show-merchant'"
                  @click="onClickOpenMerchant(data.item.id)"
                >
                  <div>
                    <feather-icon icon="ActivityIcon" />
                    Manage Merchant
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  v-can="'edit-merchant'"
                  @click="onClickOpenMerchantAdmin(data.item.id, data.item.admin.id)"
                >
                  <feather-icon icon="UserIcon" />
                  Manage Merchant Admin
                </b-dropdown-item>
                <b-dropdown-item
                  v-can="'edit-merchant'"
                  @click="onClickOpenMerchantSettings(data.item.id)"
                >
                  <feather-icon icon="SettingsIcon" />
                  Manage Merchant Settings
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canEditSalesPerson"
                  @click="onClickOpenSalesInformation(data.item.id)"
                >
                  <feather-icon icon="BriefcaseIcon" />
                  Manage Sales Information
                </b-dropdown-item>
                <b-dropdown-item
                  v-can="'view-merchant-business'"
                  @click="onClickOpenMerchantBusinesses(data.item.id)"
                >
                  <feather-icon icon="TruckIcon" />
                  Manage Merchant Businesses
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row
              cols="12"
            >
              <b-col
                class="d-flex align-items-center justify-content-sm-start"
                md="3"
              >
                <label class="width-75">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block width-100"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                class="d-flex align-items-center justify-content-sm-end"
                md="9"
              >
                <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="(meta.total)? meta.total : 0"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </div>
    </b-card>

    <create-merchant-modal ref="add_new_merchant_modal" />
    <update-merchant-modal ref="update_merchant_modal" />
    <update-merchant-settings-modal ref="update_merchant_settings_modal" />
    <update-merchant-user-modal ref="update_merchant_user_modal" />
    <third-party-integration-modal ref="third_party_integration_modal" />
    <update-sales-information-modal ref="update_sales_infromation_modal" />
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BAvatar,
  VBTooltip,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { timestampToDateTime } from '@core/utils/timeUtil'
import flatPickr from 'vue-flatpickr-component'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import store from '@/store/index'
import CreateMerchantModal from '@/components/merchant/CreateMerchantModal.vue'
import UpdateMerchantModal from '@/components/merchant/UpdateMerchantModal.vue'
import UpdateMerchantSettingsModal from '@/components/merchant/UpdateMerchantSettingsModal.vue'
import UpdateSalesInformationModal from '@/components/merchant/UpdateSalesInformationModal.vue'
import UpdateMerchantUserModal from '@/components/merchant/UpdateMerchantUserModal.vue'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const MerchantRepository = RepositoryFactory.get('merchant')

function initialState() {
  return {
    avatarText,
    timestampToDateTime,
    filters: {},
    total: 0,
    meta: {},
    dataLoading: false,
    sort: '',
    perPage: 20,
    pageOptions: [10, 20, 50],
    totalRows: 1,
    page: 1,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    statusOptions: [
      {
        name: 'Active',
        key: 'active',
      },
      {
        name: 'Inactive',
        key: 'inactive',
      },
      {
        name: 'New',
        key: 'new',
      },
    ],
    fields: [
      {
        key: 'id', label: 'Ref No', sortable: true, thClass: 'd-none', tdClass: 'd-none',
      },
      {
        key: 'name', label: 'Merchant', sortable: true, thClass: 'text-center',
      },
      {
        key: 'default_address', label: 'Primary Business Address', sortable: false, thClass: 'text-left',
      },
      {
        key: 'contact_no', label: 'Primary Business Contact', sortable: false, thClass: 'text-left',
      },
      {
        key: 'admin_details', label: 'Admin Details', sortable: false, thClass: 'text-left',
      },
      {
        key: 'rate_card', label: 'Rate Card ', sortable: false, thClass: 'text-left',
      },
      store.getters.permissions.filter(permission => permission === 'show-sales-person').length > 0 ? {
        key: 'sales_person.name', label: 'Sales Person', sortable: true, thClass: ' text-left',
      } : {},
      {
        key: 'action', label: 'Action', sortable: false, thClass: 'text-center',
      },
    ],
    json_fields: {
      REF: 'ref_no',
      'Merchant Name': {
        field: 'name',
        callback: value => `"${value}"`,
      },
      Active: 'is_active',
      'Primary Address': {
        callback: value => `"${value.default_address !== null ? `${value.default_address.address_line_1}` : 'N/A'} ${value.default_address !== null && value.default_address.address_line_2 !== null ? `${value.default_address.address_line_2}` : ''} ${value.default_address !== null && value.default_address.address_line_3 !== null ? `${value.default_address.address_line_3}` : ''} ${value.default_address !== null ? `${value.default_address.city.name}` : ''}"`,
      },
      'Primary Business Contact': {
        callback: value => `"${value.default_address !== null && value.default_address.default_contact_details.length > 0 ? value.default_address.default_contact_details[0].phone : 'N/A'}"`,
      },
      'Admin Name': {
        callback: value => `${value.admin ? `"${value.admin.first_name} ${value.admin.last_name}"` : ''}`,
      },
      'Admin Email': 'admin.email',
      'Admin Phone Number': 'admin.phone',
      'Rate Card': {
        field: 'rate_card',
        callback: value => `"${value}"`,
      },
      ...(store.getters.permissions.filter(permission => permission === 'show-sales-person').length > 0 ? {
        'Sales Person': {
          callback: value => `"${value.sales_person ? `${value.sales_person.name}` : 'N/A'}"`,
        },
      } : {}),
    },
    rows: [],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCardActions,
    BPagination,
    BFormGroup,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BOverlay,
    vSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    BAvatar,
    CreateMerchantModal,
    UpdateMerchantModal,
    UpdateMerchantSettingsModal,
    UpdateMerchantUserModal,
    UpdateSalesInformationModal,
    flatPickr,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  computed: {
    canEditSalesPerson() {
      return store.getters.permissions.filter(permission => permission === 'edit-sales-person').length > 0
    },
    canShowSalesPerson() {
      return store.getters.permissions.filter(permission => permission === 'show-sales-person').length > 0
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    page() {
      this.fetchMerchantList()
    },
    perPage() {
      this.fetchMerchantList()
    },
  },
  mounted() {
    this.fetchMerchantList()
  },
  methods: {
    onClickOpenAddMerchantModal() {
      this.$refs.add_new_merchant_modal.openModal()
    },
    onClickOpenMerchant(id) {
      this.$refs.update_merchant_modal.openModal(id)
    },
    onClickOpenMerchantAdmin(id, adminId) {
      this.$refs.update_merchant_user_modal.openModal(id, adminId, 'admin')
    },
    onClickOpenMerchantSettings(id) {
      this.$refs.update_merchant_settings_modal.openModal(id)
    },
    onClickOpenMerchantBusinesses(id) {
      this.$router.push({ name: 'merchant-details-management', params: { id } })
    },
    onClickOpenThirdPartyIntegration(id) {
      this.$refs.third_party_integration_modal.openModal(id)
    },
    onClickOpenSalesInformation(id) {
      this.$refs.update_sales_infromation_modal.openModal(id)
    },
    async fetchMerchantList() {
      this.dataLoading = true
      this.rows = []
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await MerchantRepository.getMerchantList(this.page, this.filterQuery, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchMerchantListNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await MerchantRepository.getMerchantListNoPagination(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    applyFilters() {
      this.fetchMerchantList()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchMerchantList()
      })
    },
    handleChangePage(page) {
      this.page = page
      this.fetchMerchantList()
    },
    changePage(value) {
      this.perPage = value
    },
    onClickRefresh() {
      this.fetchMerchantList()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select.scss';
@import '../../@core/scss/vue/libs/vue-flatpicker.scss';
.dropdown .dropdown-menu {
  max-height: 135px;
  overflow-y: auto;
}
</style>
