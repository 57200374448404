<template>
  <b-modal
    id="modal-merchant-add"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="ADD NEW MERCHANT"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="createMerchantForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickCreateMerchant)"
        >
          <b-row>
            <b-col
              cols="12"
              class="d-flex justify-content-center"
            >
              <div class="d-flex mt-1 mb-1">
                <feather-icon
                  icon="BriefcaseIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-0 ml-md-50 sub-heading">
                  Merchant Primary Business Details
                </h4>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                class="required"
                label="Business Name"
                label-for="h-name"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the merchant business name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="Address Line 1"
                label-for="h-addressLine1"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="addressLine1"
                  rules="required"
                >
                  <b-form-input
                    id="addressLine1"
                    v-model="form.addressLine1"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the address line 1"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Address Line 2"
                label-for="h-addressLine2"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="addressLine2"
                  rules=""
                >
                  <b-form-input
                    id="addressLine2"
                    v-model="form.addressLine2"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the address line 2"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Address Line 3"
                label-for="h-addressLine3"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="addressLine3"
                  rules=""
                >
                  <b-form-input
                    id="addressLine3"
                    v-model="form.addressLine3"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the address line 3"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="Postal /Zip Code"
                label-for="h-postalCode"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="postalCode"
                  rules="required"
                >
                  <b-form-input
                    id="postalCode"
                    v-model="form.postalCode"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the postal /zip Code"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="Country"
                label-for="h-country"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="country"
                  rules="required"
                >
                  <v-select
                    v-model="form.country"
                    :options="countryOptions"
                    :reduce="option => option.id"
                    label="name"
                    autocomplete="nope"
                    placeholder="Select the country"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="City"
                label-for="h-city"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="city"
                  rules="required"
                >
                  <v-select
                    v-model="form.city"
                    :options="cityOptions"
                    :reduce="option => option.id"
                    label="name"
                    autocomplete="nope"
                    placeholder="Select the city"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row />
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="Contact No."
                label-for="h-contactNo"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="contactNo"
                  rules="required"
                >
                  <b-form-input
                    id="contactNo"
                    v-model="form.contactNo"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the contact no."
                    type="number"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="Email"
                label-for="h-email"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="email"
                  rules="required"
                >
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the email"
                    type="email"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <!--          <b-row>-->
          <!--            <b-col cols="12">-->
          <!--              <b-form-group-->
          <!--                label="Ref No."-->
          <!--                label-for="h-refNo"-->
          <!--                label-cols-md="4"-->
          <!--                label-align="right"-->
          <!--              >-->
          <!--                <ValidationProvider-->
          <!--                  #default="{ errors }"-->
          <!--                  name="refNo"-->
          <!--                  rules="required"-->
          <!--                >-->
          <!--                  <b-form-input-->
          <!--                    id="refNo"-->
          <!--                    v-model="form.refNo"-->
          <!--                    :state="errors.length > 0 ? false:null"-->
          <!--                    placeholder="Enter the ref no. (Ex:- MC-XXXX)"-->
          <!--                    type="text"-->
          <!--                    class="col-md-8"-->
          <!--                  />-->
          <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
          <!--                </ValidationProvider>-->
          <!--              </b-form-group>-->
          <!--            </b-col>-->
          <!--          </b-row>-->
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Status"
                label-for="h-status"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="status"
                  rules=""
                >
                  <b-form-group>
                    <b-form-checkbox
                      id="status"
                      v-model="form.status"
                      value="active"
                      unchecked-value="inactive"
                      class="mt-1"
                    >
                      Active
                    </b-form-checkbox>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                v-if="merchantType"
                label="Merchant Type:"
                label-for="h-status"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="merchantType"
                  rules=""
                >
                  <b-form-group>
                    <b-form-radio-group
                      v-model="merchantTypeSelected"
                      :options="merchantTypeOptions"
                      class="mt-1 mb-1"
                      value-field="value"
                      text-field="text"
                      disabled-field="disabled"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              class="d-flex justify-content-center"
            >
              <div class="d-flex mt-1 mb-1">
                <feather-icon
                  icon="UserIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-0 ml-md-50 sub-heading">
                  Merchant Admin Details
                </h4>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="Username"
                label-for="h-username"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="form.username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the username"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="Password"
                label-for="h-password"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-form-input
                    id="password"
                    v-model="form.password"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the password"
                    type="password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="First Name"
                label-for="h-firstName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="firstName"
                  rules="required"
                >
                  <b-form-input
                    id="firstName"
                    v-model="form.firstName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the first name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="Last Name"
                label-for="h-lastName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="lastName"
                  rules="required"
                >
                  <b-form-input
                    id="lastName"
                    v-model="form.lastName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the last name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="Email"
                label-for="h-userEmail"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="userEmail"
                  rules="required"
                >
                  <b-form-input
                    id="userEmail"
                    v-model="form.userEmail"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the admin email"
                    type="email"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="Contact No."
                label-for="h-userContactNo"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="userContactNo"
                  rules="required"
                >
                  <b-form-input
                    id="userContactNo"
                    v-model="form.userContactNo"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the contactNo"
                    type="number"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              class="d-flex justify-content-center"
            >
              <div class="d-flex mt-1 mb-1">
                <feather-icon
                  icon="DollarSignIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-0 ml-md-50 sub-heading">
                  Bank Details
                </h4>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="Account Name"
                label-for="h-accountName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="accountName"
                  rules="required"
                >
                  <b-form-input
                    id="accountName"
                    v-model="form.accountName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the account name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="Account No."
                label-for="h-accountNo"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="accountNo"
                  rules="required"
                >
                  <b-form-input
                    id="accountNo"
                    v-model="form.accountNo"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the account no"
                    type="number"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="Bank Name"
                label-for="h-bankName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="bankName"
                  rules="required"
                >
                  <b-form-input
                    id="bankName"
                    v-model="form.bankName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the bank name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                class="required"
                label="Branch Name"
                label-for="h-branchName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="branchName"
                  rules="required"
                >
                  <b-form-input
                    id="branchName"
                    v-model="form.branchName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the branch name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Branch Code"
                label-for="h-branchCode"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="branchCode"
                  rules="required"
                >
                  <b-form-input
                    id="branchCode"
                    v-model="form.branchCode"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the branch code"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- submit and reset -->
          <b-row>
            <b-col
              cols="12"
              class="d-flex justify-content-center"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                class="mr-1 custom-button-color"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Clear
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BFormCheckbox,
  BRow,
  BOverlay,
  VBModal,
  BFormRadioGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('resource')
const MerchantRepository = RepositoryFactory.get('merchant')
const MerchantSettingsRepository = RepositoryFactory.get('merchantSetting')

function initialState() {
  return {
    open: false,
    id: null,
    form: {},
    modalLoading: false,
    loading: false,
    cityOptions: [],
    stateOptions: [],
    countryOptions: [],
    merchantType: null,
    merchantTypeSelected: 'credit',
    merchantTypeOptions: [
      { text: 'Credit', value: 'credit', disabled: false },
      { text: 'Cash', value: 'cash', disabled: false },
    ],
    search: '',
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BFormCheckbox,
    BRow,
    BOverlay,
    'v-select': vSelect,
    BFormRadioGroup,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal() {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.modalLoading = true
      await this.fetchCountryList()
      await this.fetchCityList()
      await this.getMerchantSetting()
      this.modalLoading = false
    },
    async fetchCityList() {
      try {
        this.cityOptions = localStorage.getItem('city_list') ? JSON.parse(localStorage.getItem('city_list')) : (await ResourceRepository.getCityDropdownListNoFilter(this.search)).data.data
        if (!localStorage.getItem('city_list')) localStorage.setItem('city_list', JSON.stringify(this.cityOptions))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async fetchCountryList() {
      try {
        const { data } = (await ResourceRepository.getCountryList()).data
        this.countryOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getMerchantSetting() {
      try {
        const { data } = (await MerchantSettingsRepository.getMerchantSettingsResource()).data
        this.merchantType = data.merchant_type_select
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },

    async onClickCreateMerchant() {
      this.modalLoading = true
      try {
        const payload = {
          name: this.form.name,
          business_name: this.form.name,
          is_active: this.form.status === 'active' ? 'active' : 'inactive',
          bank_details: {
            account_name: this.form.accountName,
            account_number: this.form.accountNo,
            bank_name: this.form.bankName,
            branch_name: this.form.branchName,
            branch_code: this.form.branchCode,
          },
          user_details: {
            first_name: this.form.firstName,
            last_name: this.form.lastName,
            email: this.form.userEmail,
            user_name: this.form.username,
            password: this.form.password,
            phone: this.form.userContactNo,
          },
          address_details: {
            address_line_1: this.form.addressLine1,
            address_line_2: this.form.addressLine2,
            address_line_3: this.form.addressLine3,
            city_id: this.form.city,
            state_id: '',
            phone: this.form.contactNo,
            country_id: this.form.country,
            postal_code: this.form.postalCode,
            type: 'general',
            is_active: (this.form.status === 'true'),
            is_default: true,
          },
          contact_details: {
            phone: this.form.contactNo,
            email: this.form.email,
            is_default: true,
          },
          business_setting: {
            merchant_type: this.merchantTypeSelected,
          },
        }
        await MerchantRepository.createMerchant(payload)
        this.showSuccessMessage('Merchant created successfully')
        this.open = false
        this.$parent.onClickRefresh()
        localStorage.removeItem('merchant_list')
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
</style>
<style lang="scss" scoped>
@import '@core/scss/vue/required.scss';
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
.sub-heading {
  text-align: center;
}
</style>
